<template>
  <TopHeader />

  <div class="mobile:mt-[80px] desktop:mt-[120px] block flex-col">
    <div
      class="flex mobile:gap-2 mobile:w-full desktop:w-auto justify-center desktop:gap-8 desktop:flex-row"
    >
      <BigButton
        :link="`#price`"
        class="!mt-0 mb-10 px-1 mx-0"
        :has-icon="false"
        :has-arrow="true"
        :blank="false"
        @click="goToPrice($event)"
      >
        К прайсу
      </BigButton>
      <BigButton
        :link="`https://t.me/Marydons`"
        class="!mt-0 mb-10 px-1 mx-0"
        :has-icon="false"
        :has-tg="true"
        :blank="false"
      >
        Написать
      </BigButton>
    </div>
  </div>

  <PriceBlock />
  <PlatformBlock />
  <WorkBlock />
  <FaqBlock />
  <FooterBlock />
</template>

<script setup>
import TopHeader from "@/views/block/TopHeader.vue";
import BigButton from "@/components/BigButton.vue";
import PriceBlock from "@/views/block/PriceBlock.vue";
import PlatformBlock from "@/views/block/PlatformBlock.vue";
import WorkBlock from "@/views/block/WorkBlock.vue";
import FaqBlock from "@/views/block/FaqBlock.vue";
import FooterBlock from "@/views/block/FooterBlock.vue";

const goToPrice = (event) => {
  event.preventDefault();
  const targetId = event.target.getAttribute("href").substring(1);
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    window.scrollTo({
      top: targetElement.offsetTop - 120,
      behavior: "smooth",
    });
  }
};
</script>
