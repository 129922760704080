export const employee_list = [
  {
    src: require("@/assets/employee/maria.jpg"),
    name: "Мари Донская",
    post: "Генеральный директор",
  },
  {
    src: require("@/assets/employee/vlad.jpg"),
    name: "Владислав Власов",
    post: "Менеджер проектов",
  },
  // {
  //   src: require("@/assets/employee/yuri.jpg"),
  //   name: "Юрий Котов",
  //   post: "Менеджер спецпроектов",
  // },
  {
    src: require("@/assets/employee/nastya.jpg"),
    name: "Анастасия Милькевич",
    post: "Продюсер проектов ",
  },
  {
    src: require("@/assets/employee/alexandr.png"),
    name: "Александр Козлов",
    post: "Креативный директор",
  },
  {
    src: require("@/assets/employee/daniil.jpg"),
    name: "Даниил Ефремов",
    post: "Менеджер по размещениям",
  },
];
